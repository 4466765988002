body {
    font-family: 'Inter';
}

.register-body {
    height: 100vh;
}

.register-bg {
    background-image: url(../images/bg-register.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.register-bg {
    padding: 55px;
}

.form-container {
    margin: 30px;
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
}

.form-grid {
    background-image: url(../images/bg-register-dots.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #f0f2f3; */
    padding: 55px 10%;
}

.form-container h4 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-top: 50px;
}

.form-container p {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    color: #7e7e7e;
    margin: 0;
    padding: 0;
    margin-bottom: 50px;
}

.header-link {
    text-align: right;
    font-size: 14px;
}

.header-link-top a {
    color: #ff3e3e;
}

.header-link-bottom {
    display: none;
}

.header-link-top {
    display: block;
}

.register-body .MuiOutlinedInput-root {
    border: 1px solid #d9d9d9 !important;
    border-radius: 15px !important;
    background: #ffffff;
}

.btn-container {
    display: block;
    text-align: right;
    margin-top: 15px;
}

.btn-container .btn-submit {
    background-color: #27a857;
    border-radius: 30px;
    font-size: 18px;
}

.agreement {
    font-weight: 300;
    font-size: 14px;
    color: #5a5a5a;
    padding: 25px 5%;
    text-align: center;
}

@media (max-width: 900px) {
    .register-bg {
        background-image: none;
        padding-top: 30px;
        padding-bottom: 0;
    }

    .register-bg img {
        margin: 0 auto;
        display: block;
        width: 215px;
    }

    .btn-container .btn-submit {
        width: 100%;
    }

    .header-link-top {
        display: none;
    }

    .header-link-bottom {
        display: block;
        color: #000000;
        margin-top: 25px;
    }

    .header-link-bottom a {
        color: #ff3e3e;
    }

    .form-grid {
        padding: 20px 10%;
    }
}

.confirm-image-container {
    background: url(../images/mail.png) no-repeat center,
        linear-gradient(165.77deg, #fc4404 1.63%, #e54870 95.75%);
}

.confirm-modal-container {
    padding: 0px !important;
}

.confirm-text-container {
    padding: 40px;
}

.confirm-text-container h2 {
    color: #505050;
}

.confirm-text-container p {
    color: #6d6d6d;
}

.confirm-text-container span {
    color: #ff3e3e;
    text-decoration: underline;
}
