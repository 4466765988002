.vip-1 .login-person {
    background-image: url(../../images/vip/1/login-bg.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
}
.vip-1 .login-person img {
    float: right;
    margin-top: 40px;
    margin-right: 40px;
}

.vip-1.mobile .login-person,
.vip-1 .form-container .sub-title,
.vip-1 .form-container .login-with-google,
.vip-1 .form-container .login-continue-with,
.vip-1 .login-action-bottom,
.vip-1 .log-action {
    display: none !important;
}

.vip-1.login-body .upviews-logo {
    flex-basis: 100%;
    max-width: 100%;
    text-align: center !important;
}

.vip-1 .form-container .login-header {
    margin-bottom: 10px !important;
}
.vip-1 .form-container .login-header h4 {
    font-size: 28px;
}

.vip-1 .vip-company {
    display: flex;
    justify-content: center;
    align-content: center;
}
.vip-1 .vip-company .vip-logo {
    margin-top: 5px;
    background-color: #000000;
    height: 59px;
    width: 59px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vip-1 .vip-company .vip-logo img {
    width: 69%;
}
.vip-1 .vip-company .vip-detail {
    margin-left: 10px;
}
.vip-1 .vip-company .vip-detail div:first-of-type {
    color: #ff3e3e;
    font-size: 32px;
    font-weight: bold;
}
.vip-1 .vip-company .vip-detail div:last-of-type {
    color: #78797a;
}

/* New campaign carousel */

.new-order .container .testimony .details .gradient-bg {
    background-image: none !important;
}
.new-order .container .testimony .vip-info {
    font-size: 14px;
}

/* left navigation  */
.drawer-vip .MuiPaper-root {
    background-color: #000 !important;
}
.drawer .vip-logo {
    padding: 10px 28px;
}
.drawer .vip-logo img {
    position: absolute;
    bottom: 20px;
}

/* deposit fund */

.deposit-modal-container .payment-options-container.desktop.vip {
  background: url(../../../assets/images/vip/1/deposit-fund-bg.png) #111413;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
}
.ads-container .greetings.greetings-vip {
  background: #000 !important;
}