body {
    font-family: 'Inter';
}

.login-body {
    height: 100vh;
    background-image: url(../images/bg-login-dots.png);
    background-position: left top;
    background-repeat: no-repeat;
}

.login-body .log-action {
    text-align: right;
}

.login-body .log-action p {
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.login-body .log-action a {
    color: #ff3e3e;
}

.login-action-bottom {
    display: none;
}

.login-body .login-nav {
    padding: 30px 4%;
}

.login-body .form-container h4 {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-top: 50px;
}

.login-body .form-container p {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    color: #7e7e7e;
    margin: 0;
    padding: 0;
    margin-bottom: 100px;
}

.MuiButton-root.login-button {
    background-color: #27a857;
    border-radius: 30px;
    padding: 13px;
    font-size: 18px;
    min-width: 173px;
}

.login-action {
    text-align: right;
}

.login-form .MuiOutlinedInput-root {
    border: 1px solid #d9d9d9 !important;
    border-radius: 15px !important;
    background: #ffffff;
}

.login-person {
    background-image: url(../images/bg-login-person.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
}
.login-person-loading .image-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recover-password-person {
    background-image: url(../images/bg-forgot-password.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
}

.forgot-password-confirmation-person {
    background-image: url(../images/bg-forgot-password-confirmation.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
}

@media (max-width: 900px) {
    .login-body .log-action {
        display: none;
    }

    .login-body .login-action-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0px;
        width: 98%;
        background-color: rgb(249, 249, 249);
        height: 50px;
    }

    .login-body .login-action-bottom p {
        padding: 0;
        margin: 0;
        font-size: 14px;
    }

    .login-body .login-action-bottom a {
        color: #ff3e3e;
    }

    .login-button {
        width: 100%;
    }

    .login-body img {
        margin: 0 auto;
        display: block;
        width: 215px;
    }
}
